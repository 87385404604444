import Meta from '@com/Meta';
import ScrollToTop from '@com/ScrollToTop';
import loadable from '@loadable/component';
import '@style/App.css';
import '@style/Global.css';
import '@style/MultiSelect.css';
import '@style/Toastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './assets/css/style.scss';
import StateProvider from './state';

const App = loadable(() => import('./App'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 2,
      retryDelay: 5 * 1000,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <HelmetProvider>
    <Meta title={process.env.REACT_APP_TITLE} />
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <StateProvider>
          <ToastContainer
            autoClose={3000}
            closeOnClick
            draggable
            hideProgressBar={false}
            newestOnTop={false}
            pauseOnFocusLoss={false}
            pauseOnHover
            position="top-right"
            rtl={false}
            theme="dark"
          />
          <ScrollToTop />
          <App />
        </StateProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </HelmetProvider>
);
